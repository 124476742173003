.Project{
  width: 40vw;
  height: 100vh;
  margin-left: 7vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-wrap: wrap;


  &__Title{
    width: auto;
    height: auto;
    color: white;
    font-weight: 600;
    letter-spacing: 10px;
    text-transform: uppercase;
    font-size: 40px;
    margin-top: 120px;
    mix-blend-mode: difference;
    position: relative;
    overflow: hidden;

    //Animacion
    #TitleProjectBlock{
      width: 100%;
      height: 100%;
      position: absolute;
      background: white;
    }
  }

  &__Description{
    width: 90%;
    color: white;
    letter-spacing: 1px;
    line-height: 170%;
    font-size: 18px;
    font-weight: 100;
    margin-top: 25px;
  }
}