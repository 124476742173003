.Loader{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  background: white;
  position: absolute;
  top: 0;
  flex-direction: column;

  .TItle{
    font-size: 22px;
    margin-bottom: 15px;
  }
}