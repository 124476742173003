
.Info{
  width: 100vw;
  height: 100vh;
  position: relative;
  top: 0;left: 0;
  padding: 0 5%;
  //Top bar
  .TopBar{
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .Autor{
      width: 30%;
      color: white;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 25px;
      
    }
  }

  //MidleBar
  .MiddleBar{
    width: 100%;
    height: calc(100% - 300px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

    .NameProject{
      width: 100%;
      height: 100px;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .Name{
        width: auto;
        font-size: 60px;
        color: white;
        letter-spacing: 5px;
        font-weight: 500;
      }
    }

    .AboutProject{
      width: 100%;
      height: auto;
      margin-bottom: 100px;
      margin-top: 20px;

      .About{
        color: white;
        width: 550px;
        height: auto;
        letter-spacing: 1px;
        line-height: 170%;
        font-weight: 300;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        flex-direction: column;

        .Button{
          width: 190px;
          height: 50px;
          color: white;
          border: solid 1px ;
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 25px;
          font-size: 15px;
        }
      }
    }
  }
  //Botom Bar
  .BottomBar{
    width: 80%;
    height: 150px;
    position: absolute;
    bottom: 0; 

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .BottomBlock{
      width: 30%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;

      .BottomInfo{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        //border-left: solid 3px white;
        padding-left: 15px;

        &__Title{
          width: 100%;
          text-align: left;
          color: white;
          letter-spacing: 2px;
          font-weight: 400;
          font-size: 20px;
        }

        &__Text{
          width: 100%;
          text-align: left;
          color: white;
          margin-top: 10px;
          line-height: 160%;
          font-weight: 100;
        }

      }
    }
  }
}


.color{
  color: #02DA9F !important;
}