*{
  box-sizing: border-box;
  font-family: montserrat;
  margin: 0;
  padding: 0;
}
*::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
  background: transparent;        /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: transparent;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}

.App{
  width: 100%;
  height: 100vh;
}
b{
  font-weight: 300;
  margin-left: 5px;
}